import { Account, Business, BusinessAccount, User } from '../interfaces';
import { KybStatus } from '../enums';

export type SelectableAccount =
  | {
      isBusiness: false;
      account: Account;
      accountId?: string;
    }
  | {
      isBusiness: true;
      account: BusinessAccount;
      accountId?: string;
      business?: Business;
    };

export type SelectableAccountsModel = {
  businessAccounts: SelectableAccount[];
  customerAccounts: SelectableAccount[];
};

export const getBusinessSelectableAccounts = (user: User): SelectableAccount[] => {
  return (
    (user.business_accounts?.map((ba: BusinessAccount) => {
      return {
        isBusiness: true,
        business: ba.business,
        account: ba as BusinessAccount,
        accountId: (ba as BusinessAccount)?.business?.account_id,
      };
    }) as SelectableAccount[]) || []
  );
};

export const getSelectableAccounts = (user: User): SelectableAccountsModel => {
  return {
    businessAccounts: getBusinessSelectableAccounts(user),
    customerAccounts: [
      {
        isBusiness: false,
        account: user.account as Account,
        accountId: user.owned_account_id,
      },
    ],
  };
};

export const formatUpperCase = (upperCaseText: string): string => {
  const lowerCaseText: string = upperCaseText.toLocaleLowerCase();

  return (lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1)).replaceAll('_', ' ');
};

export const getBusinessMenuItemTitle = (selectableAccount: SelectableAccount, user: User): string => {
  if (!selectableAccount.isBusiness) {
    return null!;
  }

  const kybStatus: string =
    selectableAccount?.account?.business?.kyb_status &&
    selectableAccount?.account?.business?.kyb_status !== KybStatus.PASS
      ? `(${formatUpperCase(selectableAccount?.account?.business?.kyb_status)})`
      : '';

  return `${selectableAccount?.account?.business?.business_name} ${kybStatus}`;
};

export const getMenuItemTitle = (selectableAccount: SelectableAccount, user: User): string =>
  selectableAccount?.isBusiness
    ? getBusinessMenuItemTitle(selectableAccount, user)
    : `${user.first_name} ${user.last_name}`;
