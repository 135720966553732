import React from 'react';
import { CInvalidFeedback, CLabel, CInputGroup, CInputCheckbox, CFormGroup } from '@coreui/react';
import classNames from 'classnames';
import { Control, useController, useWatch } from 'react-hook-form';

export interface CheckboxGroupOptions {
  label: string;
  key: string;
}

interface CheckboxGroupProps extends CInputCheckbox {
  control: Control<any, any>;
  options: CheckboxGroupOptions[];
  name: string;
  title?: string;
  id?: string;
  required?: boolean;

  error?: string;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  fullWidth?: boolean;
}
export default function CheckboxGroup({
  control,
  name,
  id = name,
  title,
  options,
  required,
  error,
  wrapperClassName,
  wrapperStyle,
  disabled,
  fullWidth = false,
}: CheckboxGroupProps) {
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  const checkboxIds = useWatch({ control, name }) || [];

  const handleChange = (value: string | number) => {
    const newArray = [...checkboxIds];
    const item = value;
    //Ensure array isnt empty
    if (newArray.length > 0) {
      //Attempt to find an item in array with matching id
      const index = newArray.findIndex((x) => x === item);
      // If theres no match add item to the array
      if (index === -1) {
        newArray.push(item);
      } else {
        //If there is a match and the value is empty, remove the item from the array
        newArray.splice(index, 1);
      }
    } else {
      //If the array is empty, add the item to the array
      newArray.push(item);
    }
    //Overwrite existing array with newArray}
    onChange(newArray);
  };

  const RequiredText = <span className="color-danger font-weight-bold"> *</span>;
  return (
    <div
      className={classNames(
        'd-flex flex-column mb-3',
        {
          'w-100': fullWidth,
        },
        wrapperClassName
      )}
      style={wrapperStyle}
    >
      {!!id && !!title && (
        <CLabel htmlFor={id} className="input-label mb-2">
          <>
            {title}
            {required && RequiredText}
          </>
        </CLabel>
      )}
      <CInputGroup htmlFor={id} className="d-block">
        {!!error && <CInvalidFeedback className="always-show-error  mb-3">{error}</CInvalidFeedback>}
        {options.map((option, index) => {
          return (
            <CFormGroup
              key={option.key}
              variant="custom-checkbox"
              className={index !== options.length - 1 ? 'pb-3' : ''}
            >
              <CInputCheckbox
                custom
                id={`checkBox-${option.key}`}
                checked={value?.some((checked: string | number) => checked === option.key)}
                {...inputProps}
                ref={ref}
                onChange={() => handleChange(option.key)}
                disabled={disabled}
              />
              <CLabel variant="custom-checkbox" htmlFor={`checkBox-${option.key}`} className="gray-checkbox">
                <p className="p-extra-small">{option.label}</p>
              </CLabel>
            </CFormGroup>
          );
        })}
      </CInputGroup>
    </div>
  );
}
