import React, { useState } from 'react';
import classnames from 'classnames';
interface AvatarProps {
  className?: string;
  firstName?: string;
  lastName?: string;
  uri?: string;
  size?: number;
  backgroundColor?: string;
}

export default function Avatar({ firstName, lastName, uri, size = 42, className, backgroundColor }: AvatarProps) {
  const styles = {
    container: {
      height: size,
      width: size,
      borderRadius: size / 2,
      backgroundColor: backgroundColor,
    },
  };
  const [imageLoadError, setImageLoadError] = useState(false);

  const firstLetter = firstName && firstName.length > 0 ? firstName.charAt(0) : '';
  const secondLetter = lastName && lastName.length > 0 ? lastName.charAt(0) : '';

  return (
    <div className={classnames(['fluz-avatar', className])} style={styles.container}>
      {(!uri || imageLoadError) && (firstName || lastName) ? (
        <div style={styles.container}>
          <p
            className="text-center"
            style={{
              fontSize: size / 2.3,
              textTransform: 'uppercase',
              lineHeight: size * 1.15 + 'px',
            }}
          >{`${firstLetter}${secondLetter}`}</p>
        </div>
      ) : (
        <img onError={() => setImageLoadError(true)} style={styles.container} src={uri} height={size} width={size} />
      )}
    </div>
  );
}
