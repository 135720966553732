import {
  CCreateElement,
  CSidebar,
  CSidebarFooter,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
} from '@coreui/react';
import classNames from 'classnames';
import React, { ComponentProps, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import fluzLogo from '../../assets/images/fluz-icons/fluz-logo-full.svg';
import ArrowLeft from '../../assets/images/misc-icons/arrow-left.svg';
import useOnLogout from '../../library/hooks/user/useOnLogout';
import ProfileCard from '../../views/user/ProfileCard';
import { Dropdown } from 'react-bootstrap';
import { Account, BusinessAccount, User, UserProfileContext } from '../../library/interfaces';
import { getMenuItemTitle, getSelectableAccounts, SelectableAccount } from '../../library/controllers/user';
import { setAccessToken, setRefreshToken } from '../../store/auth/actions';
import useCommonRequestOptions, {
  ChangeSessionContextActionParams,
  RefreshTokenResponse,
} from '../../services/useCommonRequestOptions';
import ky from 'ky';
import { UserState } from '../../store/user/reducer';
import Avatar from '../Avatar';

import { ReactComponent as ArrowRight } from '../../assets/images/misc-icons/arrow-right-emph.svg';

export default function Header(): ReactElement {
  const has_developer_access = useSelector((state: RootStateOrAny) => state?.user?.user?.has_developer_access);
  const hasAccountSwitcher = useSelector((state: RootStateOrAny) => state.user?.user?.account?.has_account_switcher);
  const user = useSelector((state: RootStateOrAny) => state.user?.user);
  const [isOpen, setIsOpen] = useState(false);
  const onLogout = useOnLogout();
  const { pathname } = useLocation();
  const drawerRoutes: { [key: string]: any }[] = [
    {
      _tag: 'CSidebarNavItem',
      name: 'Account settings',
      to: '/account-settings',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Monthly statements',
      to: '/monthly-statements',
      exact: false,
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Downloads',
      to: '/downloads',
      exact: false,
    },
    ...(has_developer_access
      ? [
          {
            _tag: 'CSidebarNavItem',
            name: 'Developer',
            to: '/developer',
            exact: false,
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (pathname && isOpen) setIsOpen(false);
  }, [pathname]);

  function handleOnProfileClick() {
    setIsOpen((prevState) => !prevState);
  }
  function handleCloseDrawer() {
    setIsOpen(false);
  }

  return (
    <>
      <a href="/">
        <img height="29" src={fluzLogo} />
      </a>
      <div
        className={classNames('drawer-wrapper', {
          open: isOpen,
        })}
      >
        <ProfileCard onClick={handleOnProfileClick} />
        <CSidebar
          colorScheme="light"
          show={isOpen}
          className={classNames('drawer')}
          onShowChange={handleOnProfileClick}
          dropdownMode="openActive"
          aside
          size="lg"
          hideOnMobileClick
        >
          <div className="pb-4 pl-4 pr-4" style={{ paddingTop: '4rem' }}>
            <div className="btn-icon" onClick={handleCloseDrawer}>
              <img src={ArrowLeft} />
            </div>
            {hasAccountSwitcher ? (
              <AccountSwitcher />
            ) : (
              <div className="d-flex gap-2 align-items-center">
                <Avatar
                  className="d-none d-sm-block"
                  firstName={user?.first_name}
                  lastName={user?.last_name || ''}
                  uri={user?.account?.account_avatar_url}
                />
                <div className="d-flex flex-column flex-fill">
                  <p className="p-regular p-regular-gray-1000" style={{ fontWeight: '700' }}>
                    {user?.email_address}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="border-bottom" />
          <CSidebarNav>
            <CCreateElement
              items={drawerRoutes}
              components={{
                CSidebarNavDivider,
                CSidebarNavDropdown,
                CSidebarNavItem,
                CSidebarNavTitle,
              }}
            />
          </CSidebarNav>
          <CSidebarFooter>
            <button className="btn btn-gray-1000" onClick={onLogout}>
              Logout
            </button>
          </CSidebarFooter>
        </CSidebar>
        <div className="backdrop" onClick={handleCloseDrawer} />
      </div>
    </>
  );
}

const AccountSwitcher = () => {
  const { user } = useSelector((state: RootStateOrAny) => state.user as UserState);
  const { refreshToken } = useSelector((state: RootStateOrAny) => state.auth);
  const dispatch = useDispatch();
  const selectableAccounts = getSelectableAccounts(user);
  const { getCommonRequestOptions } = useCommonRequestOptions();

  const personalAccount = selectableAccounts.customerAccounts[0];

  const onAccountChange = async (profile: SelectableAccount) => {
    const params: ChangeSessionContextActionParams = {
      ...getChangeSessionContextActionParams(profile.account, user.user_id),
      refreshToken,
    };
    const refreshingToken = ky
      .post('change-context', { ...getCommonRequestOptions('auth'), json: params })
      .json()
      .then((tokenResponse) => {
        return tokenResponse;
      });
    const tokenResponse: RefreshTokenResponse = (await refreshingToken) as RefreshTokenResponse;

    if (tokenResponse.accessToken) {
      dispatch(setAccessToken(tokenResponse.accessToken));
      window.location.reload();
    } else {
      throw new Error('No access-token');
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={ProfileCardMemoToggle} />
      <Dropdown.Menu className="account-switcher-dropdown" align={'right'}>
        <Dropdown.Header>Personal account</Dropdown.Header>
        <Dropdown.Divider />
        <Dropdown.Item
          active={user?.selected_account_id === user?.account?.account_id}
          onClick={() => onAccountChange(personalAccount)}
        >
          <AccountItem
            subTitle="Personal account"
            uri={user?.account?.account_avatar_url}
            firstName={user?.first_name}
            lastName={user?.last_name}
            title={user?.email_address}
          />
        </Dropdown.Item>
        <Dropdown.Header>External accounts</Dropdown.Header>
        <Dropdown.Divider />
        {selectableAccounts.businessAccounts.map((ba, i) => {
          const title = getMenuItemTitle(ba, user);
          const account = ba.account as BusinessAccount;
          return (
            <Dropdown.Item
              active={account.account_id === user?.selected_account_id}
              disabled={!ba.accountId}
              key={(ba?.accountId || '') + i}
              onClick={() => onAccountChange(ba)}
            >
              <AccountItem title={title} subTitle="Business account" />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const AccountItem = ({
  uri,
  firstName,
  lastName,
  title,
  subTitle,
}: {
  uri?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  subTitle?: string;
}) => {
  const _title = firstName || lastName ? `${firstName} ${lastName}` : title;

  return (
    <div className="d-flex gap-2 align-items-center">
      <Avatar className="d-none d-sm-block" firstName={firstName || title} lastName={lastName || ''} uri={uri} />
      <div className="d-flex flex-column flex-fill">
        <p className="p-regular p-regular-gray-1000" style={{ fontWeight: '700' }}>
          {_title}
        </p>
        <p className="p-small p-small-gray-500">{subTitle}</p>
      </div>
      <ArrowRight fill="#9DA5B1" width={16} height={16} />
    </div>
  );
};

const ProfileCardMemoToggle = React.forwardRef((props: ComponentProps<any>, ref: any) => {
  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      <ProfileCard onClick={() => undefined} className="mt-4" variant="secondary" />
    </div>
  );
});

export type UserAccount = Account | BusinessAccount;

export function isBusinessAccount(account: UserAccount): account is BusinessAccount {
  return !!(account as BusinessAccount)?.business_account_id;
}

export function getChangeSessionContextActionParams(
  account: UserAccount,
  userId: string
): ChangeSessionContextActionParams {
  const context = isBusinessAccount(account) ? UserProfileContext.BUSINESS : UserProfileContext.CONSUMER;
  const contextId = isBusinessAccount(account) ? account.business?.account_id : account?.account_id;
  if (!contextId) {
    throw new Error(`Error switching accounts: Selected ${context} account_id is missing!`);
  }
  return {
    userId,
    sessionContext: context,
    sessionContextId: contextId,
    refreshToken: '',
  };
}
